import Vue from 'vue';
import Vuex from 'vuex';

import {user} from './user.module'
import {usuario} from './usuario.module'
import {franquicia} from './franquicia.module'
import {pais} from './pais.module'
import {codigoPostal} from './codigoPostal.module'
import {servicios} from './servicios.module'
import {envio} from './envio.module'
import {envios} from './envios.module'
import {masivo} from './masivo.module'
import {cliente} from './cliente.module'
import {factura} from './factura.module'
import {libretaContacto} from './libretaContacto.module'
import {incidencia} from './incidencia.module'
import {parada} from './parada.module'
import {rma} from './rma.module'
import { partner } from "./partner.module"
import { puntoRed } from "./puntoRed.module"


Vue.use(Vuex);

export const store = new Vuex.Store({
  modules:{
    user,
    usuario,
    franquicia,
    pais,
    codigoPostal,
    servicios,
    envio,
    envios,
    masivo,
    cliente,
    factura,
    libretaContacto,
    incidencia,
    parada,
    rma,
    partner,
    puntoRed
  }
})