import { envioService, } from '@/_services';

export const envios = {
  namespaced: true,
  state: {
    list: {
      data: [],
      status: {},
    },
    seguimiento: {
      data: [],
      status: {}
    },
    enviosExcel:{
      data: [],
      status: {}
    }
  },
  mutations: {
    envioExcelRequest(state){
      state.enviosExcel.status={
        loading:true
      }
    },
    envioExcelSuccess(state, pdf){
      state.enviosExcel.data = pdf
      state.enviosExcel.status={
        loaded:true
      }
    },
    envioExcelError(state, error){
      state.enviosExcel.data = null
      state.enviosExcel.status={
        error
      }
    },
    getEnviosRequest(state) {
      state.list.status = {
        loading: true,
      };
    },
    cancelEnviosRequest(state, error) {
      state.list.data = [];
      state.list.status = {
        loading: false,
        error,
      };
    },
    getEnviosSuccess(state, env) {
      state.list.data = env;
      state.list.status = {
        loaded: true,
      };
    },
    getEnviosSeguimientoRequest(state) {
      state.seguimiento.status = {
        loading: true,
      };
    },
    cancelEnviosSeguimientoRequest(state, error) {
      state.seguimiento.data = [];
      state.seguimiento.status = {
        loading: false,
        error,
      };
    },
    getEnviosSeguimientoSuccess(state, env) {
      state.seguimiento.data = env;
      state.seguimiento.status = {
        loaded: true,
      };
    },
  },
  actions: {
    getAllEnvios({ commit }, req) {
      commit('getEnviosRequest');
      envioService.getAllEnvios(req.pagination, req.filters)
        .then((env) => {
          commit('getEnviosSuccess', env);
        })
        .catch((error) => {
          commit('cancelEnviosRequest', error);
        });
    },
    getEnvioSeguimiento({ commit }, filter) {
      commit('getEnviosSeguimientoRequest');
      envioService.getEnvioSeguimiento(filter)
        .then((env) => {
          commit('getEnviosSeguimientoSuccess', env);
        })
        .catch((error) => {
          commit('cancelEnviosSeguimientoRequest', error);
        });
    },
    envioExcel({ commit }, filters) {
      commit('envioExcelRequest');
      envioService
        .getEnviosAsExcel(filters)
        .then((excel) => {
          commit('envioExcelSuccess', excel);
        })
        .catch((error) => {
          commit('envioExcelError', error);
        });
    },
  },
};
