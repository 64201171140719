/*!

=========================================================
* Vue Light Bootstrap Dashboard - v1.4.0
=========================================================

* Product Page: http://www.creative-tim.com/product/vue-light-bootstrap-dashboard-pro
* Copyright 2019 Creative Tim (http://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard/blob/master/LICENSE.md)

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

 */
import Vue from "vue";
import { userService, clienteService } from "@/_services";

import "./assets/css/element-ui/theme-chalk/index.css";
import locale from "./assets/css/element-ui/locale/lang/es";

import VueRouter from "vue-router";
import { store } from "./_store/index";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
import { BootstrapIconsPlugin } from "bootstrap-icons-vue";

import ElementUI from "element-ui";
import LightBootstrap from "./light-bootstrap-main";
// Plugins
import App from "./App.vue";

import * as VueGoogleMaps from "vue2-google-maps";
import { apiKeyMaps } from "./globalVars";
import VueCookies from "vue-cookies";

// router setup
import routes from "./routes/routes";

// mascaras
import VueTheMask from "vue-the-mask";
Vue.use(VueTheMask);
Vue.use(VueGoogleMaps, {
  load: {
    key: apiKeyMaps,
    libraries: "places, drawing, visualization",
    installComponents: true,
  },
});

Vue.use(VueCookies, { expires: "120h" });
// plugin setup
Vue.use(VueRouter);
Vue.use(BootstrapIconsPlugin);
Vue.use(ElementUI, {
  locale,
});

Vue.use(LightBootstrap);

// configure router
const router = new VueRouter({
  routes, // short for routes: routes
  linkActiveClass: "active",
});

router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = [
    "/login",
    "/seguimiento",
    "/recover_password",
    "/restore_login",
    "/rma",
    "/rma/generico",
    "/forbidden",
  ];
  const authRequired = !publicPages.includes(to.path);
  const user = JSON.parse(localStorage.getItem("user"));

  if (authRequired) {
    if (!user) return next("/login");
    else {
      if (!userService.esCliente(user)) {
        return next("/login");
      } else {
        return next();
      }
    }
  }

  next();
});

/* eslint-disable no-new */
new Vue({
  el: "#app",
  render: (h) => h(App),
  store,
  router,
});

clienteService.loadCliente();
clienteService.reloadCliente();
localStorage.removeItem("userName");
