<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <notifications></notifications>
    <side-bar :backgroundColor="colorBackground" id=sidebar>
      <mobile-menu></mobile-menu>
      <template v-slot:links> 
        <sidebar-item :hideClassName="hideClassName"
          :link="{ name: 'Mis Envíos', icon: 'nc-icon nc-delivery-fast', path: '/cliente/envios' }">
        </sidebar-item>
        <sidebar-item :hideClassName="hideClassName"
          :link="{ name: 'Envíos Importaciones', icon: 'nc-icon nc-cloud-upload-94', path: '/cliente/importacion' }">
        </sidebar-item>
        <sidebar-item :hideClassName="hideClassName"
          :link="{ name: 'RMA', icon: 'nc-icon nc-refresh-02', path: '/cliente/rma' }">
        </sidebar-item>
        <sidebar-item :hideClassName="hideClassName"
          :link="{ name: 'RMA Importaciones', icon: 'nc-icon nc-cloud-upload-94', path: '/cliente/ImportacionesRma' }">
        </sidebar-item>
        <sidebar-item :hideClassName="hideClassName"
          :link="{ name: 'Facturas', icon: 'nc-icon nc-money-coins', path: '/cliente/facturas' }">
        </sidebar-item>
        <sidebar-item :hideClassName="hideClassName"
          :link="{ name: 'Libreta de Contactos', icon: 'nc-icon nc-badge', path: '/cliente/libretaContacto' }">
        </sidebar-item>
        <sidebar-item :hideClassName="hideClassName"
          :link="{ name: 'Gestión de Incidencias', icon: 'nc-icon nc-settings-tool-66', path: '/cliente/incidencias' }">
        </sidebar-item>
        <sidebar-item :hideClassName="hideClassName"
          :link="{ name: 'Usuarios', icon: 'nc-icon nc-single-02', path: '/cliente/usuarios' }">
        </sidebar-item>       
      </template>
    </side-bar>
    <div class="main-panel">
      <top-navbar></top-navbar>

      <dashboard-content @click="toggleSidebar"> </dashboard-content>

      <content-footer></content-footer>
    </div>
  </div>
</template>
<script>
import TopNavbar from "./TopNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import DashboardContent from "./Content.vue";
import MobileMenu from "./Extra/MobileMenu.vue";
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

export default {
  components: {
    TopNavbar,
    ContentFooter,
    DashboardContent,
    MobileMenu
  },
  data() {
    return {
      hideClassName: "show-item",
      resizeObserver: null,
      colorBackground:"black",
    };
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
    initScrollbar() {
      let docClasses = document.body.classList;
      let isWindows = navigator.platform.startsWith("Win");
      if (isWindows) {
        // if we are on windows OS we activate the perfectScrollbar function
        initScrollbar("main-panel");

        docClasses.add("perfect-scrollbar-on");
      } else {
        docClasses.add("perfect-scrollbar-off");
      }
    },
    onResize() {
      let sidebar = document.getElementById("sidebar").clientWidth;
      if (sidebar == 80) {
        this.hideClassName = "hide-item";
        return
      }
      this.hideClassName = "show-item";
    }
  },
  mounted() {
    if(process.env.NODE_ENV === 'production'){
      this.colorBackground="black";
    }else this.colorBackground="purple"
    this.$sidebar.ocultarSideBar();
    this.initScrollbar();
    this.resizeObserver = new ResizeObserver(this.onResize)
    let sidebar = document.getElementById("sidebar");
    this.resizeObserver.observe(sidebar)
  },
  beforeUnmount() {
    let sidebar = document.getElementById("sidebar");
    this.resizeObserver.unobserve(sidebar)
  }
};
</script>
